<script>
export default {
	name: 'BaseButton',
	functional: true,
	props: {
		tag: {
			type: String,
			default: 'button',
		},
		large: {
			type: Boolean,
			default: false,
		},
		slim: {
			type: Boolean,
			default: false,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		grey: {
			type: Boolean,
			default: false,
		},
		light: {
			type: Boolean,
			default: false,
		},
		innerClass: {
			type: String,
			default: '',
		},
	},
	render(_, { props, slots, data }) {
		const Tag = props.tag;
		const html = data?.domProps?.innerHTML;
		const text = data?.domProps?.textContent;
		const slot = slots().default;
		const icon = slots().icon;
		const innerClasses = `c-base-button__inner ${props.innerClass}`;
		html && delete data.domProps.innerHTML;
		text && delete data.domProps.textContent;
		/**
		 * Disables click-events if aria-disabled
		 * is set to true.
		 */
		if (
			data?.attrs &&
			typeof data.attrs['aria-disabled'] === 'string' &&
			data.attrs['aria-disabled'].toLowerCase() === 'true'
		) {
			!data?.on && (data.on = {});
			data.on.click = (event) => event.preventDefault();
		}
		const bindings = {
			...data,
			...data.attrs,
			class: [
				{
					'c-base-button--large': props.large,
					'c-base-button--slim': props.slim,
					'c-base-button--dark': props.dark,
					'c-base-button--grey': props.grey,
					'c-base-button--light': props.light,
					'c-base-button--icon-only': icon && !(html || text || slot),
				},

				'c-base-button',
				data.class,
			],
			staticClass: data.staticClass,
		};
		return (
			<Tag {...bindings}>
				<span class={innerClasses}>
					{html && !text && (
						<span
							class="c-base-button__text <768:w-full"
							domPropsInnerHTML={html}
						></span>
					)}
					{(text || slot) && (
						<span class="c-base-button__text <768:w-full">
							{text || slot}
						</span>
					)}
					{icon && <span class="c-base-button__icon">{icon}</span>}
				</span>
			</Tag>
		);
	},
};
</script>
<style lang="postcss">
.c-base-button:not(.c-base-button--large) {
	@apply uppercase;
}
.c-base-button__inner {
	@apply relative flex items-center gap-x-sm >=768:justify-between;
	@apply bg-primary px-lg py-8;
	@apply font-darker-grotesque font-bold text-button-xs;
	@apply h-full overflow-hidden;
	/* To have prism-transitioning and opacity transitioning co-exist */
	transition-property: opacity, background-color, border-color, color;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 200ms, var(--scoped-color-theme-duration, 0s),
		var(--scoped-color-theme-duration, 0s),
		var(--scoped-color-theme-duration, 0s);
	@apply -mx-4;
	width: calc(100% + 4px * 2);
	border-radius: 9999px;
}
.c-base-button[aria-disabled='true'] .c-base-button__inner {
	@apply opacity-10;
}

/** Slim */
.c-base-button--slim .c-base-button__inner {
	padding-left: calc(
		2px + var(--theme-spacing-sm, var(--theme-spacing-sm--sm))
	);
	padding-right: calc(
		4px + var(--theme-spacing-sm, var(--theme-spacing-sm--sm))
	);

	@apply mx-0 w-full;
}

/** Large */
.c-base-button--large .c-base-button__inner {
	@apply pl-3xl pr-4xl py-18 -mx-10;
	@apply text-button font-semibold;
	@apply -mx-10;
	width: calc(100% + 10px * 2);
}
/** Text & icon */
.c-base-button__text {
	@apply mb-4;
	word-break: break-word;
	z-index: 1;
}
.c-base-button__icon {
	@apply flex-shrink-0 w-sm;
	@apply duration-500 ease-smooth-out;
	max-width: 37.5%;
	z-index: 1;
}
/** Text & icon, type specific */
.c-base-button--large .c-base-button__text {
	@apply flex-1;
}
.c-base-button--large .c-base-button__icon {
	@apply absolute right-lg;
	transform: translateY(-50%);
	top: 50%;
}
/** Hover effect */
.c-base-button__inner:before {
	@apply absolute -left-full -top-1 -bottom-1 w-full;
	@apply rounded-r-full bg-secondary bg-opacity-20;
	@apply duration-500 ease-smooth-out transform opacity-50;
	content: '';
	z-index: 0;
}
.c-base-button:hover .c-base-button__inner:before {
	@apply translate-x-full opacity-100;
}
.c-base-button:not(.c-base-button--icon-only):hover .c-base-button__icon {
	transform: translateX(8px);
}
.c-base-button--large:hover .c-base-button__icon {
	transform: translate(8px, -50%) !important;
}
.c-base-button--slim:hover .c-base-button__icon {
	transform: translateX(4px) !important;
}

/** Dark */
.c-base-button--dark .c-base-button__inner {
	@apply bg-text text-white;
}
.c-base-button--dark .c-base-button__inner:before {
	@apply bg-white bg-opacity-20;
}

/** Grey */
.c-base-button--grey .c-base-button__inner {
	@apply bg-background text-text;
}

.c-base-button--grey .c-base-button__inner:before {
	@apply bg-black bg-opacity-10;
}

/** Light */
.c-base-button--light .c-base-button__inner {
	@apply bg-white text-text;
	@apply border-2 border-text;
}

.c-base-button--light .c-base-button__inner:before {
	@apply bg-text bg-opacity-10;
}

/** Disabled */
.c-base-button[aria-disabled='true'] {
	@apply pointer-events-none;
}

/** Mobile */
@screen <1024 {
	.c-base-button__inner {
		@apply -mx-3 !important;
		width: calc(100% + 3px * 2) !important;
	}
	.c-base-button__text {
		@apply mb-2;
	}
}
.c-base-button--icon-only {
	@apply w-auto h-auto;
}
.c-base-button--icon-only .c-base-button__inner {
	@apply mx-0 w-full h-full p-0 justify-center !important;
}
</style>
